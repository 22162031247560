<script setup>
import { ref, watch } from 'vue';
import moment from 'moment';

import ModalFullSize from '@/components/ui/modals/ModalFullSize.vue';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import Pager from '@/components/ui/pager/components/Pager.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';

import flagSurveyService from '@/services/flag-survey';

const props = defineProps({
  isView: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['select-flag-survey', 'close-modal']);

const isLoading = ref(false);
const flagSurveys = ref([]);
const flagSurveysMeta = ref({});

const fetchFlagSurveys = async (page = 1) => {
  isLoading.value = true;
  const { flagsurveys, count, page_count } =
    await flagSurveyService.fetchFlagSurveys({
      page,
      limit: 20,
    });
  flagSurveys.value = flagsurveys;
  flagSurveysMeta.value = { page, count, page_count };
  isLoading.value = false;
};
const selectFlagSurvey = flagSurvey => {
  emit('select-flag-survey', flagSurvey);
};
const movePage = ({ next }) => {
  fetchFlagSurveys(next);
};

watch(
  () => props.isView,
  newIsView => {
    if (newIsView) {
      fetchFlagSurveys();
    }
  },
);
</script>

<template>
  <modal-full-size :is-view="isView" @close-modal="emit('close-modal')">
    <template #header>送信するアンケートの選択</template>
    <template #body>
      <div class="body-wrapper">
        <div class="body-inner">
          <div class="body-header">
            <div v-if="!isLoading" class="pager-wrapper">
              <pager
                :page="flagSurveysMeta.page"
                :count="flagSurveysMeta.count"
                :page-count="flagSurveysMeta.page_count"
                @move="movePage"
              />
            </div>
          </div>
          <div v-if="isLoading" class="page-loading">
            <LocalPageLoader />
          </div>
          <div v-else-if="!isLoading && flagSurveys.length === 0">
            <EmptyContent>
              <p>
                アンケートがありません
                <br />
                あらかじめ「応募者へ通知」からアンケートを
                <br />
                作成している必要があります
              </p>
            </EmptyContent>
          </div>
          <div v-else>
            <div class="table-wrapper">
              <table class="table">
                <thead>
                  <th>アンケート名</th>
                  <th class="fit-center">質問数</th>
                  <th>作成者</th>
                  <th>作成日</th>
                </thead>
                <tbody>
                  <tr
                    v-for="flagSurvey in flagSurveys"
                    :key="flagSurvey.id"
                    @click="selectFlagSurvey(flagSurvey)"
                  >
                    <td class="text-ellipsis">{{ flagSurvey.title }}</td>
                    <td class="fit-center">{{ flagSurvey.count }}</td>
                    <td>{{ flagSurvey.staff.name }}</td>
                    <td>
                      {{
                        moment(flagSurvey.created_at).format('YYYY-MM-DD HH:mm')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="!isLoading" class="pager-wrapper">
            <pager
              :page="flagSurveysMeta.page"
              :count="flagSurveysMeta.count"
              :page-count="flagSurveysMeta.page_count"
              @move="movePage"
            />
          </div>
        </div>
      </div>
      <div class="btn-col">
        <button-base
          type="button"
          class="btn-cancel"
          button-type="secondary"
          button-text="キャンセル"
          @click="emit('close-modal')"
        />
      </div>
    </template>
  </modal-full-size>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
.body-wrapper {
  padding: 20px;
  background-color: $white;
}
//body
.body-inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.body-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}

.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

//テーブル
.table-wrapper {
  overflow-x: auto;
  min-height: 400px;
}
.table {
  width: 100%;
  white-space: nowrap;
  font-size: $font_14;
  text-align: left;
  thead {
    background: $mochica_color_gray2;
  }
  th,
  td {
    min-width: 220px;
    &.fit-center {
      width: 0;
      min-width: unset;
      white-space: nowrap;
      text-align: center;
    }
    &.text-ellipsis {
      max-width: 20em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.img {
      img {
        max-width: 50px;
      }
    }
  }
  th {
    padding: 12px 10px;
    &:first-of-type {
      padding: 12px 10px 12px 20px;
    }
    &:last-of-type {
      padding: 12px 20px 12px 10px;
    }
  }
  td {
    padding: 18px 10px;
    &:first-of-type {
      padding: 18px 10px 18px 20px;
    }
    &:last-of-type {
      padding: 18px 20px 18px 10px;
    }
  }
  tr {
    border-bottom: 1px solid #e8e8e8;
    &:hover {
      background: #f1f1f1;
    }
  }
}
.btn-cancel {
  width: 100%;
  height: 46px;
  margin-top: 20px;
}
</style>
