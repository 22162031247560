<template>
  <div v-if="$store.getters['page/isPageLoaded']">
    <bread-crumb-list>
      <template #breadcrumb>
        <span class="breadcrumb">
          <router-link to="/announce">応募者への通知</router-link>
        </span>
        <span class="breadcrumb breadcrumb-right-arrow">アンケート</span>
      </template>
    </bread-crumb-list>
    <section class="content-warp">
      <div class="content">
        <div class="headline-typeC">アンケートを通知する</div>
        <div class="header-block">
          <button-base
            class="btn"
            button-text="アンケートの新規作成"
            icon-file-name="add_circle_white"
            @click="openRegisterFlagSurveyModal()"
          />
        </div>

        <div class="col-right margin-left-auto flexbox">
          <div class="area-pager margin-left-auto">
            <pager
              :page="page"
              :count="count"
              :page-count="pageCount"
              @move="movePager"
            />
          </div>
        </div>

        <div class="scroll-table">
          <table class="table-typeB">
            <thead class="thead">
              <tr class="row">
                <th class="item-th">アンケート名</th>
                <th class="item-th">質問数</th>
                <th class="item-th">作成者</th>
                <th class="item-th">作成日</th>
                <th class="item-th">機能</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr
                v-for="(list, iList) in flagSurveyList"
                :key="iList"
                class="row"
              >
                <td class="item-td" @click="openSendFlagSurveyModal(list)">
                  {{ list.title }}
                </td>
                <td class="item-td" @click="openSendFlagSurveyModal(list)">
                  {{ list.count }}
                </td>
                <td class="item-td" @click="openSendFlagSurveyModal(list)">
                  {{ list.staff.name }}
                </td>
                <td class="item-td" @click="openSendFlagSurveyModal(list)">
                  {{
                    $utils.parseDateTime(
                      list.created_at,
                      'YYYY年MM月DD日 HH:mm',
                    )
                  }}
                </td>
                <td class="item-td">
                  <button-base
                    class="btn btn-availability"
                    button-text="編集"
                    icon-file-name="pen_white"
                    :is-loading="isLoading"
                    @click="openEditFlagSurveyModal(list)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <flag-survey-detail-modal
        :is-visible="isShowFlagSurveyRegisterModal"
        :flag-survey-id="selectedFlagSurveyId"
        :flag-survey-detail-mode="flagSurveyDetailMode"
        :flag-survey-mode="FLAG_SURVEY_MODE"
        @onClickCancel="resetModalStates"
        @onReload="onReload"
        @onClickSubmitSendModal="onClickSubmitSendModal"
      />
      <flag-survey-destination
        :is-visible="isShowFlagSurveyDestinationModal"
        :flag-survey-id="selectedFlagSurveyId"
        :send-limit="SEND_LIMIT"
        @onClickCancel="resetModalStates"
        @onClickSettle="sendFlagSurvey"
        @onChangeAppSelect="onChangeAppSelect"
      />
      <edit-disabled-modal
        :is-view="isViewEditDisabledModal"
        :actions="actions"
        :target-name="'アンケート'"
        :action-type="ACTION_TYPE_LABELS[ACTION_TYPES.flagSurvey]"
        :operation-name="'編集'"
        @close="isViewEditDisabledModal = false"
      />
    </section>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import flagsurveyService from '@/services/flag-survey';
import BreadCrumbList from '@/components/ui/breadCrumbList/components/BreadCrumbList';
import Pager from '@/components/ui/pager/components/Pager';
import FlagSurveyDetailModal from '@/components/page/flagsurvey/components/FlagSurveyDetailModal';
import FlagSurveyDestination from '@/components/page/flagsurvey/components/FlagSurveyDestination';
import EditDisabledModal from '@/components/features/autoActions/modals/EditDisabledModal';
import autoActionsService from '@/services/autoActions.js';
import { ACTION_TYPES, ACTION_TYPE_LABELS } from '@/defines/autoActions';

export default defineComponent({
  name: 'FlagSurvey',
  components: {
    FlagSurveyDetailModal,
    FlagSurveyDestination,
    BreadCrumbList,
    Pager,
    EditDisabledModal,
  },
  setup(props, context) {
    const SEND_LIMIT = 1000;
    const LIMIT = 25;
    const FLAG_SURVEY_MODE = Object.freeze({
      REGIST: Symbol('flagSurveyRegist'),
      EDIT: Symbol('flagSurveyEdit'),
      DETAIL: Symbol('flagSurveyDetail'),
    });
    const store = useStore();
    const router = useRouter();
    const flagSurveyDetailMode = ref(Symbol(''));
    const isShowFlagSurveyRegisterModal = ref(false);
    const isShowFlagSurveyDestinationModal = ref(false);
    const flagSurveyList = ref([]);
    const selectedApplicants = ref([]);
    const selectedFlagSurveyId = ref(null);
    const page = ref(0);
    const count = ref(0);
    const pageCount = ref(0);

    const isLoading = ref(false);
    const actions = ref([]);
    const isViewEditDisabledModal = ref(false);

    const fetchAll = async (tmpPage = 1) => {
      const result = await flagsurveyService.fetchFlagSurveys({
        page: tmpPage,
        limit: LIMIT,
      });
      page.value = tmpPage;
      count.value = result.count;
      pageCount.value = result.page_count;
      if (result.success) {
        flagSurveyList.value = result.flagsurveys;
      } else {
        store.dispatch('notification/VISIBLE_NOTIFICATION', {
          message: result.message,
          type: result.success,
        });
      }
    };
    const onReload = async () => {
      await fetchAll();
      resetModalStates();
    };
    const sendFlagSurvey = async flagSurveyId => {
      const sendObj = {
        flagsurvey_id: flagSurveyId,
        applicant_ids: selectedApplicants.value,
        announced_id: null,
      };
      const res = await flagsurveyService.sendFlagSurvey(sendObj);
      store.dispatch('notification/VISIBLE_NOTIFICATION', {
        message: res.message,
        type: res.success,
      });
      if (res.success === true) {
        resetModalStates();
        router.push({ name: 'FlagsurveyHistory' });
      }
    };
    const movePager = page => {
      fetchAll(page.next);
    };
    const openRegisterFlagSurveyModal = () => {
      selectedFlagSurveyId.value = null;
      flagSurveyDetailMode.value = FLAG_SURVEY_MODE.REGIST;
      isShowFlagSurveyRegisterModal.value = true;
    };
    const openEditFlagSurveyModal = async flagSurveyObj => {
      isLoading.value = true;
      await checkUsedAutoAction(flagSurveyObj.id);
      if (actions.value.length > 0) {
        isViewEditDisabledModal.value = true;
        isLoading.value = false;
        return;
      }
      selectedFlagSurveyId.value = flagSurveyObj.id;
      flagSurveyDetailMode.value = FLAG_SURVEY_MODE.EDIT;
      isShowFlagSurveyRegisterModal.value = true;
      isLoading.value = false;
    };
    const openSendFlagSurveyModal = flagSurveyObj => {
      selectedFlagSurveyId.value = flagSurveyObj.id;
      flagSurveyDetailMode.value = FLAG_SURVEY_MODE.DETAIL;
      isShowFlagSurveyRegisterModal.value = true;
    };
    const resetModalStates = () => {
      selectedFlagSurveyId.value = null;
      isShowFlagSurveyRegisterModal.value = false;
      isShowFlagSurveyDestinationModal.value = false;
    };
    const onClickSubmitSendModal = () => {
      isShowFlagSurveyRegisterModal.value = false;
      isShowFlagSurveyDestinationModal.value = true;
    };
    const onChangeAppSelect = payload => {
      if (payload !== undefined) selectedApplicants.value = payload;
    };

    const checkUsedAutoAction = async flagSurveyId => {
      const { data } = await autoActionsService.inUseAutoActions({
        flagsurvey_id: flagSurveyId,
      });
      actions.value = data.actions;
    };

    // lifecycle
    onBeforeMount(async () => {
      await fetchAll();
      await store.dispatch('page/SET_LOADED');
    });

    return {
      SEND_LIMIT,
      FLAG_SURVEY_MODE,
      flagSurveyDetailMode,
      isShowFlagSurveyRegisterModal,
      isShowFlagSurveyDestinationModal,
      flagSurveyList,
      selectedFlagSurveyId,
      page,
      count,
      pageCount,
      isLoading,
      actions,
      isViewEditDisabledModal,
      movePager,
      openRegisterFlagSurveyModal,
      openEditFlagSurveyModal,
      openSendFlagSurveyModal,
      resetModalStates,
      onClickSubmitSendModal,
      onReload,
      onChangeAppSelect,
      sendFlagSurvey,
      ACTION_TYPES,
      ACTION_TYPE_LABELS,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

.content {
  padding: 40px;
  background: #fff;
  .header-block {
    margin-bottom: 20px;
  }
}

.item-td {
  word-break: break-all;
}

@media (max-width: ($media_query_sp)) {
  .content {
    padding: 20px;
  }
}

.scroll-table {
  overflow-x: auto;
}
@media (max-width: ($media_query_tablet)) {
  .scroll-table {
    white-space: nowrap;
  }
}
@media (max-width: ($media_query_sp)) {
  .scroll-table {
  }
}

.table-typeB {
  table-layout: fixed;
  .row {
    cursor: pointer;
  }
  .row:hover {
    background-color: #f1f1f1;
  }
}

.area-pager {
  margin-bottom: 40px;
}

@media (max-width: ($media_query_tablet)) {
  .table-typeB {
    table-layout: auto;
  }
}

.breadcrumb-right-arrow::before {
  content: '';
  mask-image: url(../../assets/img/arrow_right_blue.svg);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  position: relative;
  background-color: #1999d6;
  height: 14.5px;
  width: 15px;
  margin-right: 3px;
  margin-left: 5px;
  top: 1px;
}
</style>
